<template>
  <!--  职称管理-->
  <div class="exercisesType">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div style="overflow: hidden;display: flex;justify-content: space-between">
        <div class="menu_select">
          <h4 style="padding: 10px">职称考试分类</h4>
          <a-menu
              style="width: 100%"
              mode="inline"
              v-model="menu"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="index"
                @click="leftChange(item.exes)"
            >
             {{item.name}}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="InfoTable">
            <a-button style="margin: 10px" type="primary" @click="()=>this.addShow = true">新增</a-button>
          <div class="Info_list">
            <div class="Info_list_Template" v-for="(item,index) in InfoTable" :key="item.id">
              <div class="Template">
                <div class="Template_pic">
                  <img style="height: 130px;width: 100%" :src="item.cover" alt="">
                </div>
                <div class="Template_title">
                  <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.name }}</span>
                  <a-switch v-model="item.is_open=== 1" checked-children="是" un-checked-children="否" @click="switchChange(item.id,item.is_open === 1? 0 : 1)"/>
                </div>
              </div>
              <a-divider style="margin: 5px 0 0 0 ;height: 1px; padding: 0" />
              <div class="Template_btn">
                <div class="btn" @click="editBtn(item.id)">编辑</div>
                <a-divider type="vertical" />
                <a-popconfirm
                    title="确定删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="delTitle(item.id)"
                    @cancel="cancel"
                >
                  <div class="btn">删除</div>
                </a-popconfirm>

              </div>
            </div>
          </div>
        </div></div>



      <a-modal v-model="addShow" title="新增" @ok="addOk">
        <a-form-model  :rules="rules"  :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
          <a-form-model-item  label="职称考试分类" prop="type">
            <a-select v-model="addForm.type"  placeholder="请选择" >
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.id">
                {{ list.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="addForm.name"/>
          </a-form-model-item>
          <a-form-model-item label="是否开启" prop="is_open">
            <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
          </a-form-model-item>
          <a-form-model-item label="封面图" prop="cover">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addCover')" ref="addCover" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="addForm.cover"
                    style="width: 95px;height:95px;"
                    :src="addForm.cover"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 宽200*高200，大小不超过500kb</span>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal v-model="editShow" title="修改" @ok="editOk">
        <a-form-model  :rules="rules"  :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
          <a-form-model-item  label="职称考试分类" prop="type">
            <a-select v-model="editForm.type"  placeholder="请选择">
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.id">
                {{ list.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="editForm.name"/>
          </a-form-model-item>
          <a-form-model-item label="是否开启" prop="is_open">
            <a-switch :checked="editForm.is_open!==0" @click="openEdit(editForm.is_open === 1? 0 : 1)"
                      checked-children="是" un-checked-children="否"  />
          </a-form-model-item>
          <a-form-model-item label="封面图" prop="cover">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:70%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('editCover')" ref="editCover" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.cover"
                    style="width: 95px;height:95px;"
                    :src="editForm.cover"
                />
              </div>
            </div>
            <span style="font-size: 10px">注：尺寸 宽200*高200，大小不超过500kb</span>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  getExercisesType,
  delExercisesType,
  putExercisesType,
  postExercisesType, getExercisesTypeId, getTitleExamList
} from "@/service/titleExam_api";
import {update} from "@/utils/update";

export default {
  name: "exercisesType",
  data(){
    return{
      addShow:false,
      editShow:false,
      spinning:false,
      editForm:{
        cover:'',
      },
      addForm:{
        cover:'',
        is_open:0,
      },
      rules:{
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        type: [
          { required: true, message: '请选择分类', trigger: 'blur' }],
        is_open: [
          { required: true, message: '是否开启', trigger: 'change' }],
        cover: [
          { required: true, message: '请上传封面图' ,trigger: 'blur'}],
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      InfoTable:[],
      titleInfo:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      menu:[0],
      typeInfo:[
      ],
      typeList:[],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"同步练习分类管理")
  },
  mounted() {
    this.getExercisesType()
  },
  methods:{
    async getExercisesType(){
      // this.spinning = true
      const Info =await getExercisesType()
      if (Info.code ===0){
        this.typeInfo = Info.data
        this.InfoTable = Info.data[0].exes
      }
      // this.spinning =false
    },
    //左侧身份选择
    leftChange(value){
      this.InfoTable = value
    },
    async switchChange(id,open){
      const data = {
        is_open : open
      }
      const Info = await  putExercisesType(id,data)
      if (Info.code === 0){
        await this.getExercisesType()
        this.InfoTable= this.typeInfo[this.menu].exes
        this.$message.success('修改成功')
      }else {
        this.$message.error('修改失败' + Info.message)
      }
    },
    async uploadPic(type){
      const _this = this;
      let file = this.$refs[type].files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = async function () {
          let w = this.width;
          let h = this.height;
          if ( file.size > 500000) {
            _this.$message.warn('请上传尺寸为200px*200px,大小500k以内的图片');
            return false;
          } else {
            const image = await update(file);
            if (image.code === 0) {
              switch (type) {
                case'addCover':
                  _this.addForm.cover = image.data.url
                  break;
                case'editCover':
                  _this.editForm.cover = image.data.url
                  break;
              }
              _this.$message.success('上传成功')
            } else {
              _this.$message.error("上传失败，请重试。", image.message)
            }
          }
        }
      }
    },
    //新增确认
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const add =await postExercisesType(this.user_name,this.addForm)
          if (add.code === 0){
            await this.getExercisesType()
            this.InfoTable= this.typeInfo[this.menu].exes
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    async editBtn(id){
      const edit = await getExercisesTypeId(id)
      this.editForm = edit.data
      this.editId = id
      this.editShow = true
    },
    openEdit(open){
      this.editForm.is_open = open
    },
    //修改职称
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit = await putExercisesType(this.editId,this.editForm)
          if (edit.code === 0){
            await this.getExercisesType()
            this.InfoTable= this.typeInfo[this.menu].exes
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除职称
    async delTitle(id){
      const Info =await delExercisesType(id)
      if (Info.code === 0){
        await this.getExercisesType()
        this.InfoTable= this.typeInfo[this.menu].exes
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },}
}
</script>

<style lang="scss" scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.exercisesType{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }
  .InfoTable{
    background-color: white;
    width: 81%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .Info_list{
      margin:  auto;
      width: 100%;
      height: 100%;
      min-height:549px;
    .Info_list_Template{
      border-radius: 3px;
      border:#DDDDDD  1px solid;
      background-color: white;
      width: 22%;
      float: left;
      height: auto;
      margin: 1%;
      .Template{
        .Template_title{
          font-size: 15px;
          font-weight: bolder;
          display: flex;
          justify-content: space-between;
          margin: 5px;
        }
      }
      .Template_btn{
        cursor: pointer;
        height: 31px;
        display: flex;
        padding: 0 !important;
        background-color:rgba(247, 249, 250, 1);
        justify-content: space-between;
        ::v-deep .ant-divider, .ant-divider-vertical{
          height: auto;
        }
        .btn{
          width:100%;
          display: flex;
          font-size: 11px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    }
  }
}


</style>
